<template>
<div>

	<div class="text-center mt-5" v-if="cargando">
		<div class="spinner-border text-primary spinner-border-sm" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<br>
		Cargando ...
	</div>

	<div v-if="!cargando" class="row">

		<div class="col-md-12">
			<form @submit.prevent="accionformulario" class="row" method="POST" enctype="multipart/form-data">

						<div class="mb-3">
							Por favor complete el siguiente formulario, los campos con <span class="text-danger">*</span> son necesarios.
						</div>


						<div class="row mb-2">
							<div class="col-md-12">
								<div class="mb-4 pb-3 border-bottom">
									<div class="h6 titulosg">
										Datos Personales
									</div>
									<div class="row mb-2">
										<div class="col-md-4">
											<label class="form-label" for="nombres"> Nombre(s): <span class="text-danger">*</span></label>
											<input required type="text" class="form-control" id="nombres" v-model="datospersona.nombres">
										</div>
						
										<div class="col-md-4">
											<label class="form-label" for="apellidos"> Apellidos: <span class="text-danger">*</span></label>
											<input required type="text" class="form-control" id="apellidos" v-model="datospersona.apellidos">
										</div>

										<div class="col-md-2" v-if="datospersona.personascategoria_idpersonascategoria != 4 ">
											<label class="form-label" for="identificacion">Tipo de Persona:  </label>
											<select id="tipoidentificacion" class="form-select" v-model="datospersona.tipopersona">
												<option value="N"> Natural </option>
												<option value="J"> Jurídica </option>
											</select>
										</div>
										
										<div class="col-md-2" v-if="datospersona.personascategoria_idpersonascategoria != 4">
											<label class="form-label" for="nit">NIT:  </label>
											<input type="number" required class="form-control" id="nit" v-model="datospersona.nit">
										</div>
									</div> 

									<div class="row mb-2">
										<div class="col-md-4">
												<label class="form-label" for="identificacion">N° Identificación: <span class="text-danger">*</span> </label>
												<input type="number" required class="form-control" id="identificacion" v-model="datospersona.identificacion" aria-describedby="basic-addon2">
										</div>
										
										<div class="col-md-4">
											<label class="form-label" for="identificacion">Tipo Identificación: <span class="text-danger">*</span> </label>
											<select required id="tipoidentificacion" class="form-select" v-model="datospersona.tipoidentificacion">
												<option value="">-</option>
												<option value="1"> CC - Cédula de ciudadanía </option>
												<option value="2"> CE - Cédula de extranjería </option>
												<option value="3"> PA - Pasaporte </option>
												<option value="4"> PET - Permiso Especial de Trabajo </option>
												<option value="5"> RC - Registro Civil</option>
												<option value="6"> TI - Tarjeta de identidad</option>
												<option value="7"> NIP - Número de Identificación Personal</option>
												<option value="8"> NUIP - Número Único de Identificación personal</option>
												<option value="9"> NES - Número de Secretaría</option>
												<option value="10">PV - Pasaporte vigente</option>
												<option value="11">MS - Menor sin identificación </option>											
											</select>
										</div>
										
									</div>

									<div class="row">
										<div class="col-md-12">
											<div class=" alert alert-warning mb-0" v-if="deshabilitar && validar.length > 0">
												 <i class="fas fa-exclamation-circle me-2"></i> El número de identificacion <strong> {{datospersona.identificacion}}</strong>  se encuentra registrado
											</div>
											<div class="text-primary p-2 mb-0" v-if="!deshabilitar">
												<i class="fas fa-check-circle"></i> El número número de identificación se valido correctamente 
											</div>
										</div>
									</div>
				 
									<div class="row mb-2">
										<div class="mt-3 h6 titulosg">
											Datos de Expedición del Documento de Identidad
										</div>
										<div class="col-md-4">
											<label class="form-label" for="lugarexpedicion">Fecha:</label>
											<input type="date" class="form-control" id="fechaexpedicion" v-model="datospersona.fechaexpedicion">
										</div>																					
									</div>

									<div class="row mb-2">
										<div class="col-md-4">
											<label class="form-label" for="deptoexpedicion">Departamento:  </label>
											<select   class="form-select" id="deptoexpedicion"  v-model="departamentoexpedicion">
												<option v-for="item in listadepartamentos" :value="item.idciudad" :key="item.idciudades"> {{item.nombreciudad}}  </option>
											</select>
										</div>
										
										<div class="col-md-4">
											<label class="form-label" for="lugarexpedicion">  Ciudad: </label>
											<select   class="form-select" id="lugarexpedicion"  v-model="datospersona.lugarexpedicion">
												<option v-for="item in listaciudadesexpedicion" :value="item.idciudad" :key="item.idciudades">{{item.nombreciudad}}  </option>
											</select>
										</div>
									</div>

									<div class="row mb-3">
										<div class="pt-3 h6 titulosg">
											Fecha y Lugar de Nacimiento
										</div>
										<div class="col-md-4">
											<label class="form-label" for="fechanacimiento">Fecha:</label>
											<input type="date" class="form-control" id="fechanacimiento" v-model="datospersona.fechanacimiento">
										</div>										
									</div>

									<div class="row mb-4">
										<div class="col-md-4">
											<label for="depto" class="form-label">Departamento:</label>
											<select class="form-select"  v-model="departamentonacimiento">
												<option v-for="item in listadepartamentos" :key="item.idciudad" :value="item.idciudad"> {{item.nombreciudad}} </option>
											</select>
										</div>

										<div class="col-md-4">
											<label class="form-label" for="lugarnacimiento">Ciudad:</label>
											<select   class="form-select" id="lugarnacimiento" v-model="datospersona.lugarnacimiento">
												<option value="0">Ciudad</option>
												<option v-for="item in listaciudadesnacimiento" :value="item.idciudad" :key="item.idciudades"> {{item.nombreciudad}}  </option>
											</select>
										</div>
									</div>
									

									<div class="row">
										<label class="form-label me-3 h6 titulosg" for="genero">Sexo:</label>
										<div class="col-md-12">
											<div class="form-check  form-check-inline">
												<input class="form-check-input" type="radio" name="genero" id="hombre"  value="H" v-model="datospersona.genero"> 
												<label   class="form-check-label" for="hombre"> Hombre </label>
											</div>
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="radio" name="genero"  id="mujer" value="M" v-model="datospersona.genero">
												<label  class="form-check-label" for="mujer"> Mujer </label>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-md-12">
								<div class="mb-4">
									<div class="h6 titulosg">
										Datos de Contacto
									</div>
									<div class="row mb-2">
										<div class="col-md-3">
											<label class="form-label" for="telefono">Teléfono:</label>
											<input type="number" min="0" minlength="6" maxlength="10" class="form-control" id="telefono" v-model="datospersona.telefono">
										</div>
										<div class="col-md-3">
											<label class="form-label" for="telefono">Celular:</label>
											<input type="number" min="0" minlength="6" maxlength="10" class="form-control" id="celular" v-model.number="datospersona.celular">
										</div>																			
									</div>

									<div class="row mb-2">
										<div class="col-md-6">
											<label class="form-label" for="email">Correo Electronico (Email):  </label>
											<input type="email"  class="form-control" id="email" v-model="datospersona.email">
										</div>
									</div>
		
										<div class="row mb-2">
											<div class="col-md-6">
												<label class="form-label">Dirección:</label>
												<input type="text" class="form-control" id="direccion" v-model="datospersona.direccion">
											</div>
											<div class="col-md-6">

												<label for="barrio" class="form-label">Barrio:</label>
												<input class="form-control" list="datalistOptions" id="barrio" v-model="datospersona.barrio">
												<datalist id="datalistOptions">
												<option v-for="(item, index) in barrios" :key="index" :value="item.barrio"></option>
												</datalist>

											</div>
										</div>

									
										<div class="row mb-2">
											<div class="col-md-3">
												<label for="departamentoresidencia" class="form-label">Departamento:</label>
												<select class="form-select" id="departamentoresidencia"  v-model="departamentoresidencia">
													<option>Seleccione</option>
													<option v-for="item in listadepartamentos" :value="item.idciudad" :key="item.idciudades">
														{{item.nombreciudad}}  </option>
												</select>
											</div>
											<div class="col-md-3">
												<label class="form-label" for="ciudad_idciudad">Ciudad: <span class="text-danger">*</span> </label>
												<select class="form-select" id="ciudad_idciudad" v-model="datospersona.ciudad_idciudad">
													<option value="">-</option>
													<option v-for="item in listaciudadesresidencia" :value="item.idciudad" :key="item.idciudades"> {{item.nombreciudad}}   </option>
												</select>
											</div>					
											<div class="col-md-2">
													<label class="form-label" for="estrato">Estrato:</label>
													<select class="form-select" id="estrato" v-model="datospersona.estrato">
														<option value="">-</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
													</select>
												</div>
										</div>					


									<div class="row mb-2">
									</div>

								</div>
							</div>
						</div>

						<div class="row mb-2">	
							<div class="col-md-12">
								<div class="mb-4">
									<div class="h6 titulosg">
										Otros Datos
									</div>
										<div class="row mb-2">
											<div class="col-md-3">
												<label class="form-label" for="tiposangre">Tipo de Sangre:</label>
												<select class="form-select" id="tiposangre" v-model="datospersona.tiposangre">
													<option value="">-</option>
													<option value="O+">O+</option>
													<option value="O-">O-</option>
													<option value="A+">A+</option>
													<option value="A-">A-</option>
													<option value="B+">B+</option>
													<option value="B-">B-</option>
													<option value="AB+">AB+</option>
													<option value="AB-">AB-</option>
												</select>
											</div>
											<div class="col-md-4">
												<label class="form-label" for="estadocivil">Estado Civil:</label>
												<select class="form-select" id="estadocivil" v-model="datospersona.estadocivil">
													<option value="">-</option>
													<option value="0">Soltero/a</option>
													<option value="1">Casado/a</option>
													<option value="2">En Relación ( más de 1 Año de noviazgo)</option>
													<option value="3">Comprometido/a</option>
													<option value="4">Unión libre o unión de hecho</option>
													<option value="5">Separado/a</option>
													<option value="6">Divorciado/a</option>
													<option value="7">Viudo/a</option>
												</select>
											</div>
										</div>

										<div class="row mb-2">
										</div>

									<div class="row mb-2">
										<div class="col-md-12">
											<label class="form-label" for="composicionfamiliar">Composición Familiar:</label>
												<input type="text" class="form-control" id="composicionfamiliar" v-model="datospersona.composicionfamiliar">
										</div>
									</div>

									<div class="row mb-2">
										<div class="col-md-12">
											<label class="form-label" for="creencias">Creencias:</label>
												<input type="text" class="form-control" id="creencias" v-model="datospersona.creencias">
										</div>
									</div>

									<div class="row mb-2 mt-3">
										<div class="col-md-12">
											<div class="alert alert-info">
												<div class="form-check">
													<input required class="form-check-input" type="checkbox" true-value="1"  false-value="0" :checked="datospersona.autorizaciondatospersonales==1" id="autorizaciondatospersonales" v-model="datospersona.autorizaciondatospersonales">
													<label class="form-check-label" for="autorizaciondatospersonales">
														Si Autorizo  de conformidad con lo dispuesto en las normas en especial la Ley 1581 de 2012 y el Decreto 1074 de 2015. el tratamiento de mis datos personales diligenciados en el presente formluario
													</label>
												</div>
											</div>
										</div>
									</div>

									<div class="row mb-2 ">
										<div class="col-md-12">
											<div class="form-floating">
												<textarea   class="form-control" id="observacion" v-model="datospersona.observacion" style="height: 100px;"></textarea>
												<label for="observacion">Observaciones</label>
											</div>
										</div>
									</div>

									<input type="hidden" id="tipopersona" v-model="datospersona.tipopersona">
									<input type="hidden" id="idpersonas" v-model="datospersona.idpersonas">
																											
								</div>
								<div class="mt-3">

									<button :disabled="deshabilitar" class="btn btn-primary mr-2" type="submit">
										<span> <i class="fas fa-save me-2"></i> Guardar Información </span>
									</button>

									<a @click="$router.go(-1)" class="btn btn-light ms-2"> Cancelar </a>

								</div>
							</div>											
						</div>

					</form>
		</div>
	</div>
</div>
</template>
<script>

	import { ref, onMounted, reactive, watch, watchEffect } from 'vue'
	import { useRouter, useRoute } from 'vue-router'

	import axios from 'axios'

	export default {
		props:['id','tipopersona'],
		setup(props) {
			
			const router = useRouter()
			const route = useRoute()

			const tipopersona = ref(props.tipopersona)
			const validar = ref([])
			const barrios = ref([])

			const listadepartamentos = ref([])
			
			const departamentoexpedicion = ref('')
			const listaciudadesexpedicion = ref([])

			const departamentonacimiento = ref('')
			const listaciudadesnacimiento = ref([])

			const departamentoresidencia = ref('')
			const listaciudadesresidencia = ref([])

			const datospersona = reactive({
				idpersonas: props.id,
				personascategoria_idpersonascategoria: props.tipopersona,
				nit: '',
				tipopersona: 'N',
				identificacion: '',
				tipoidentificacion: '',
				lugarexpedicion: 0,
				fechaexpedicion: '',
				nombres: '',
				apellidos: '',
				fechanacimiento: '',
				lugarnacimiento: 0,
				genero: '',
				telefono: '',
				celular: '',
				direccion: '',
				barrio: '',
				ciudad_idciudad: 0,
				estrato: '',
				email: '',
				estadocivil: '',
				tiposangre: '',
				composicionfamiliar: '',
				creencias: '',
				observacion: '',
				autorizaciondatospersonales: '',
				estado: ''
			})
			
			const ciudades = ref([])
			const deshabilitar = ref(true)
			const cargando = ref(true)	
			const identificacionactual = ref(true)


			const ConsultarCiudades = () => {
				axios.get('/api/ciudades').then(response => {
					ciudades.value = response.data;
					cargando.value = false;
				});
			}

			const Departamentos = () => {
				axios.get('/api/departamentos').then( response => {
					listadepartamentos.value = response.data;
				})
			}

			const validaridentificacion = (identificacion) =>{

				//si la cedula es igual a la que tiene no realice acciones
				if(identificacion == identificacionactual.value){

					deshabilitar.value = false

				}else{

					axios.get('/api/validaridentificacion/'+identificacion).then(response => { 
						
						validar.value = response.data 
						if(validar.value.length>0){ deshabilitar.value = true; }else{ deshabilitar.value = false }
						
					});
				}
			}
		
			const ConsultarBarrios = () =>  {
				axios.get('/api/consultarbarrios').then(response => { barrios.value = response.data });
			}

			const consultarInfoPersona = () =>  {
				axios.get('/api/infopersona/'+props.id).then(response => { 
					
					// datospersona.value = response.data
					identificacionactual.value = response.data.identificacion // para temas de validacion

					departamentoresidencia.value = response.data.ciudadresidencia.ciudad_idciudad
					departamentonacimiento.value = response.data.ciudadnacimiento.ciudad_idciudad
					departamentoexpedicion.value = response.data.ciudadexpedicion.ciudad_idciudad

					datospersona.personascategoria_idpersonascategoria = response.data.personascategoria_idpersonascategoria
					datospersona.nit = response.data.nit
					datospersona.tipopersona = response.data.tipopersona
					datospersona.identificacion = response.data.identificacion
					datospersona.tipoidentificacion = response.data.tipoidentificacion
					datospersona.lugarexpedicion = response.data.lugarexpedicion
					datospersona.fechaexpedicion = response.data.fechaexpedicion
					datospersona.nombres = response.data.nombres
					datospersona.apellidos = response.data.apellidos
					datospersona.fechanacimiento = response.data.fechanacimiento
					datospersona.lugarnacimiento = response.data.lugarnacimiento
					datospersona.genero = response.data.genero
					datospersona.telefono = response.data.telefono
					datospersona.celular = response.data.celular
					datospersona.direccion = response.data.direccion
					datospersona.barrio = response.data.barrio
					datospersona.ciudad_idciudad = response.data.ciudad_idciudad
					datospersona.estrato = response.data.estrato
					datospersona.email = response.data.email
					datospersona.estadocivil = response.data.estadocivil
					datospersona.tiposangre = response.data.tiposangre
					datospersona.composicionfamiliar = response.data.composicionfamiliar
					datospersona.creencias = response.data.creencias
					datospersona.observacion = response.data.observacion
					datospersona.autorizaciondatospersonales = response.data.autorizaciondatospersonales
					datospersona.estado = response.data.estado

				})
			}

			watch(departamentoexpedicion, () => { 
				axios.get('/api/ciudadxdepto/'+departamentoexpedicion.value).then( response => {
					listaciudadesexpedicion.value = response.data
				})
			})

			watch(departamentonacimiento, () => { 
				axios.get('/api/ciudadxdepto/'+departamentonacimiento.value).then( response => {
					listaciudadesnacimiento.value = response.data
				})
			})

			watch(departamentoresidencia, () => { 
				axios.get('/api/ciudadxdepto/'+departamentoresidencia.value).then( response => {
					listaciudadesresidencia.value = response.data
				})
			})

			watch(() => datospersona.identificacion, (id, anterior) => { 
					validaridentificacion(id)
			})


			const validaraccion = () => {

				if(props.id && props.id != null)
				{
					consultarInfoPersona()
				}
			}

			const accionformulario = () => {

				deshabilitar.value = true;
				let url = ""

				if(props.id && props.id != null) { 
					//Actualizar
					url = '/api/actualizarpersona'
				}else{
					//Crear
					url = '/api/agregarpersona'					
				}

				axios.post(url,{ data: datospersona }).then((response) => {

						deshabilitar.value = false;		
				 
 						swal("Ok!", "Información registrada Correctamente!", "success");
						 
						if(datospersona.personascategoria_idpersonascategoria == 4){
							router.push({ name: 'datosestudiantes', params: { id: response.data } })
						}

					}).catch(function (error) {
						console.log(error)
				})

					
					
			}

			onMounted(() => {
				ConsultarBarrios()
				Departamentos()
				ConsultarCiudades()
				validaraccion()
			})

			return {
				validar,
				identificacionactual,
				barrios,				
				tipopersona,
				datospersona,
				ciudades,
				deshabilitar,
				cargando,
				listadepartamentos,
				departamentoexpedicion,
				listaciudadesexpedicion,

				departamentonacimiento,
				listaciudadesnacimiento,

				departamentoresidencia,
				listaciudadesresidencia,

				//Funciones
				ConsultarBarrios,
				validaridentificacion,
				accionformulario

			}
		} 	 
	}
</script>
 